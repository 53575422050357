export const DB = {
    "data": [
        {
            "question": "查分系统的密码修改过但忘记了，怎么办？",
            "answer": "点击查分页面登录框右下角的“忘记密码”，输入姓名和证件号码后系统会将重设密码的链接发送至考生在学信网报名时填写的邮箱中。"
        },
        {
            "question": "查询分数以后如何得知自己本专业的分数排名？",
            "answer": "学校不进行专业排名，考生可于3月上旬向报考学院咨询。"
        },
        {
            "question": "申请成绩复核是否需要纸质材料？复核结果何时公布？",
            "answer": "成绩复核无需纸质资料，在系统中申请即可。如对自命题科目成绩有异议，考生可于2月20日至3月2日登录上海交通大学研究生招生网申请成绩复核。3月4日10:00起再次登录上海交通大学研招网查询成绩复核结果，经核查无误则成绩无变化。"
        },
        {
            "question": "在上海交通大学成绩复核系统中无法找到想要复核的科目，该怎么办？\n",
            "answer": "请核对要复核的科目是否是统考科目，如果是统考科目则无需在上交大系统中操作。可按照如下流程进行申请：如对统考及联考科目考试成绩有异议，考生3月2日（星期一）9:00至3月3日（星期二）16:00可登录上海市教育考试院“上海招考热线”网站（www.shmeea.edu.cn），点击进入首页“成绩查询”栏目，进行网上申请（不接受现场申请），具体申请流程见上海市教育考试院“上海招考热线”网站通知《2020年硕士研究生招生考试成绩即将公布》（点击查看）。3月4日（星期三）10:00起，考生可登录“上海招考热线”网站查询成绩复核结果。"
        },
        {
            "question": "准考证丢失了，如何找回准考证编号？",
            "answer": "考生可凭网报用户名和密码，登录中国研究生招生信息网再次下载打印《准考证》。"
        },
        {
            "question": "复试分数线、复试名单何时公布？",
            "answer": "学校2020年硕士研究生复试基本分数线公布及具体复试安排将根据上级主管部门工作部署和疫情防控情况适时公布，请考生安心做好复试准备，密切关注上海交通大学研究生招生网（http://yzb.sjtu.edu.cn）和所报考学院网站发布的复试时间、复试形式、复试要求等详细信息，并保持电子邮件、电话等联系方式畅通，确保复试工作顺利进行。"
        },
        {
            "question": "硕士研究生招生复试何时进行？形式是否有变化？",
            "answer": "疫情防控期间要保障师生生命安全和身体健康，要最大限度减少人员流动和聚集，因此我校研究生复试工作将根据疫情防控情况和上级主管部门部署，合理做出调整并及时向社会公布。请广大考生密切关注学校和报考学院的通知。"
        },
        {
            "question": "报考专业的招生人数多少？",
            "answer": "我已在考生报考期间已公布了各专业招生人数、已接收推免生人数和拟考试招生人数，供考生查阅和参考：https://yzb.sjtu.edu.cn/info/1005/2969.htm"
        },
        {
            "question": "少数民族高层次骨干人才计划和强军计划考生进入复试和被拟录取需要什么样的条件？",
            "answer": "报考少数民族高层次骨干人才计划和强军计划的考生，达到专项计划基本复试分数线的考生可进入复试。专项招生计划单列，择优录取，宁缺毋滥。"
        },
        {
            "question": "符合教育部相关加分政策的硕士生，应如何享受政策优惠？",
            "answer": "我校严格执行教育部有关硕士研究生招生考试的加分政策，请符合加分项目条件且有申请加分意向的考生，在我校公布基本复试分数线后，尽快联系我校研招办并提交相关证明材料。"
        },
        {
            "question": "关于调剂事宜？",
            "answer": "2020年第一志愿报考本校硕士生源比较充足，一般均不接受报考外校考生的调剂申请；报考本校的考生在学校复试基本分数线发布以后，可按需要接收调剂的相关院系发布的申请办法提出校内调剂申请；报考本校考生若满足教育部规定的调剂条件，可登录学信网申请校外调剂。校外招生单位的调剂信息，请考生密切关注学信网。"
        },
        {
            "question": "今年硕士统考是否有扩招计划？",
            "answer": "研究生招生计划由教育部下达，学校将依据教育部下达的研究生招生计划并结合学校实际办学条件和保障等确定，如有变化将会及时公布，请关注学校研招网和所报考学院网站发布的最新信息。"
        },
        {
            "question": "本学期盲审/抽检送审的截止时间是哪一天？",
            "answer": "本学期博士、硕士学位论文盲审/抽检送审的截止时间为2023年7月 6日24时（以学生本人在研究生信息系统内点击抽检的时间为准），未在截止日期前提交的送审论文，将于下学期开学第一天送审。"
        },
        {
            "question": "研究生盲审/抽检是否需要提交纸质材料到研究生院？",
            "answer": "不需要，只需要在研究生信息系统内点击“抽检”按钮，提交完成即可。"
        },
        {
            "question": "博士生/硕士生抽中后为什么没有缴费按钮？",
            "answer": "抽检抽中的研究生不需要缴费。"
        },
        {
            "question": "已经在研究生信息系统内抽检了论文，为什么系统未显示已派送？",
            "answer": "（1）已经在系统里提交抽检的论文，学位办均会及时送审；(2)为尽量缩短论文的评审周期，学位办会优先送审论文，之后再刷新送审状态。"
        },
        {
            "question": "论文的送审时间是否从显示已派送的时间开始算？",
            "answer": "否。论文的送审时间以学生在系统内提交抽检的时间为准，博士论文送审满2个月（寒暑假及国家法定节假日除外）未返回结果的，可以申请提前答辩。"
        },
        {
            "question": "硕士论文抽中的是否必须等抽检结果通过才可申请答辩？",
            "answer": "否。硕士论文抽检抽中的，通过学院组织的论文评审即可进入答辩等后续流程，若答辩后返回的抽检结果出现“不合格”情况，其正在进行的学位申请流程暂行中止，按要求修改完善论文且经导师与学院审核通过、重新归档后再行恢复。"
        },
        {
            "question": "参加国际评审但是系统里已抽中的博士生，是否要等抽检结果返回才可申请答辩？",
            "answer": "参加国际评审的，国际评审通过即可申请答辩，不需要等抽检结果返回。若答辩后返回的抽检结果出现“不合格”情况，其正在进行的学位申请流程暂行中止，按要求修改完善论文且经导师与学院审核通过、重新归档后再行恢复。"
        },
        {
            "question": "盲审/抽检已经送审了一段时间，是否要找学位办催一下评审专家？",
            "answer": "否，学位办会按照论文抽检时间的先后提醒专家按时提交评阅结果。"
        },
        {
            "question": "系统里提交了论文等待查重，请问什么时间可以查好？",
            "answer": "论文的查重由学院老师操作，具体请联系学院相关教务老师。"
        },
        {
            "question": "已经在系统里提交了论文且点击了抽检，论文是否可以退回？",
            "answer": "已经抽检的论文不能再退回。"
        },
        {
            "question": "参加国际评审应该怎么操作？",
            "answer": "参加国际评审的，须在抽检前提交国际评审申请备案材料。具体请参照《上海交通大学博士学位论文国际评审与答辩办法》（沪交研〔2021〕79号）有关规定。\n\n网上查询途径：研究生院主页—政务公开—工作手册—学位—上海交通大学博士学位论文国际评审与答辩办法。"
        }
    ],
    "index": {
        "查": [
            0,
            1,
            20,
            20
        ],
        "分": [
            0,
            1,
            1,
            5,
            9
        ],
        "系": [
            0,
            3,
            15,
            15,
            18,
            20,
            21
        ],
        "统": [
            0,
            3,
            11,
            15,
            15,
            18,
            20,
            21
        ],
        "的": [
            0,
            1,
            3,
            7,
            8,
            9,
            12,
            16,
            16,
            17,
            18
        ],
        "密": [
            0
        ],
        "码": [
            0
        ],
        "修": [
            0
        ],
        "改": [
            0
        ],
        "过": [
            0,
            17
        ],
        "但": [
            0,
            18
        ],
        "忘": [
            0
        ],
        "记": [
            0
        ],
        "了": [
            0,
            4,
            15,
            19,
            20,
            21,
            21
        ],
        "怎": [
            0,
            3,
            22
        ],
        "么": [
            0,
            3,
            8,
            14,
            15,
            20,
            22
        ],
        "办": [
            0,
            3,
            19
        ],
        "询": [
            1
        ],
        "数": [
            1,
            1,
            5,
            7,
            8
        ],
        "以": [
            1,
            20,
            21
        ],
        "后": [
            1,
            14
        ],
        "如": [
            1,
            4,
            9
        ],
        "何": [
            1,
            2,
            4,
            5,
            6,
            9
        ],
        "得": [
            1
        ],
        "知": [
            1
        ],
        "自": [
            1
        ],
        "己": [
            1
        ],
        "本": [
            1,
            12
        ],
        "专": [
            1,
            7,
            19
        ],
        "业": [
            1,
            7
        ],
        "排": [
            1
        ],
        "名": [
            1,
            5
        ],
        "申": [
            2,
            17,
            18
        ],
        "请": [
            2,
            17,
            18,
            20
        ],
        "成": [
            2,
            3
        ],
        "绩": [
            2,
            3
        ],
        "复": [
            2,
            2,
            3,
            3,
            5,
            5,
            6,
            8
        ],
        "核": [
            2,
            2,
            3,
            3
        ],
        "是": [
            2,
            6,
            11,
            12,
            13,
            16,
            17,
            18,
            18,
            19,
            21
        ],
        "否": [
            2,
            6,
            11,
            13,
            16,
            17,
            18,
            19,
            21
        ],
        "需": [
            2,
            8,
            13
        ],
        "要": [
            2,
            3,
            8,
            13,
            18,
            19
        ],
        "纸": [
            2,
            13
        ],
        "质": [
            2,
            13
        ],
        "材": [
            2,
            13
        ],
        "料": [
            2,
            13
        ],
        "结": [
            2,
            17,
            18
        ],
        "果": [
            2,
            17,
            18
        ],
        "时": [
            2,
            5,
            6,
            12,
            16,
            16,
            19,
            20
        ],
        "公": [
            2,
            5
        ],
        "布": [
            2,
            5
        ],
        "在": [
            3,
            15,
            21
        ],
        "上": [
            3
        ],
        "海": [
            3
        ],
        "交": [
            3,
            13,
            20,
            21
        ],
        "通": [
            3,
            17
        ],
        "大": [
            3
        ],
        "学": [
            3,
            12,
            19
        ],
        "中": [
            3,
            14,
            17,
            18
        ],
        "无": [
            3
        ],
        "法": [
            3
        ],
        "找": [
            3,
            4,
            19
        ],
        "到": [
            3,
            13
        ],
        "想": [
            3
        ],
        "科": [
            3
        ],
        "目": [
            3
        ],
        "该": [
            3,
            22
        ],
        "\n": [
            3
        ],
        "准": [
            4,
            4
        ],
        "考": [
            4,
            4,
            7,
            8,
            11
        ],
        "证": [
            4,
            4
        ],
        "丢": [
            4
        ],
        "失": [
            4
        ],
        "回": [
            4,
            18,
            21
        ],
        "编": [
            4
        ],
        "号": [
            4
        ],
        "试": [
            5,
            5,
            6,
            8
        ],
        "线": [
            5
        ],
        "单": [
            5
        ],
        "硕": [
            6,
            9,
            11,
            14,
            17
        ],
        "士": [
            6,
            9,
            11,
            14,
            14,
            17,
            18
        ],
        "研": [
            6,
            13,
            13,
            15
        ],
        "究": [
            6,
            13,
            13,
            15
        ],
        "生": [
            6,
            6,
            7,
            8,
            9,
            13,
            13,
            14,
            14,
            15,
            18
        ],
        "招": [
            6,
            7,
            11
        ],
        "进": [
            6,
            8
        ],
        "行": [
            6
        ],
        "形": [
            6
        ],
        "式": [
            6
        ],
        "有": [
            6,
            11,
            14
        ],
        "变": [
            6
        ],
        "化": [
            6
        ],
        "报": [
            7
        ],
        "人": [
            7,
            8
        ],
        "多": [
            7
        ],
        "少": [
            7,
            8
        ],
        "民": [
            8
        ],
        "族": [
            8
        ],
        "高": [
            8
        ],
        "层": [
            8
        ],
        "次": [
            8
        ],
        "骨": [
            8
        ],
        "干": [
            8
        ],
        "才": [
            8,
            17,
            18
        ],
        "计": [
            8,
            8,
            11
        ],
        "划": [
            8,
            8,
            11
        ],
        "和": [
            8,
            8
        ],
        "强": [
            8
        ],
        "军": [
            8
        ],
        "入": [
            8
        ],
        "被": [
            8
        ],
        "拟": [
            8
        ],
        "录": [
            8
        ],
        "取": [
            8
        ],
        "什": [
            8,
            14,
            15,
            20
        ],
        "样": [
            8
        ],
        "条": [
            8
        ],
        "件": [
            8
        ],
        "符": [
            9
        ],
        "合": [
            9
        ],
        "教": [
            9
        ],
        "育": [
            9
        ],
        "部": [
            9
        ],
        "相": [
            9
        ],
        "关": [
            9,
            10
        ],
        "加": [
            9,
            18,
            22
        ],
        "政": [
            9,
            9
        ],
        "策": [
            9,
            9
        ],
        "应": [
            9,
            22
        ],
        "享": [
            9
        ],
        "受": [
            9
        ],
        "优": [
            9
        ],
        "惠": [
            9
        ],
        "于": [
            10
        ],
        "调": [
            10
        ],
        "剂": [
            10
        ],
        "事": [
            10
        ],
        "宜": [
            10
        ],
        "今": [
            11
        ],
        "年": [
            11
        ],
        "扩": [
            11
        ],
        "期": [
            12
        ],
        "盲": [
            12,
            13,
            19
        ],
        "审": [
            12,
            12,
            13,
            16,
            18,
            19,
            19,
            19,
            22
        ],
        "抽": [
            12,
            13,
            14,
            15,
            17,
            17,
            18,
            18,
            19,
            21
        ],
        "检": [
            12,
            13,
            15,
            17,
            18,
            19,
            21
        ],
        "送": [
            12,
            15,
            16,
            16,
            19
        ],
        "截": [
            12
        ],
        "止": [
            12
        ],
        "间": [
            12,
            16,
            16,
            19,
            20
        ],
        "哪": [
            12
        ],
        "一": [
            12,
            19,
            19
        ],
        "天": [
            12
        ],
        "提": [
            13,
            20,
            21
        ],
        "院": [
            13
        ],
        "博": [
            14,
            18
        ],
        "为": [
            14,
            15
        ],
        "没": [
            14
        ],
        "缴": [
            14
        ],
        "费": [
            14
        ],
        "按": [
            14
        ],
        "钮": [
            14
        ],
        "已": [
            15,
            15,
            16,
            18,
            19,
            21
        ],
        "经": [
            15,
            19,
            21
        ],
        "信": [
            15
        ],
        "息": [
            15
        ],
        "内": [
            15
        ],
        "论": [
            15,
            16,
            17,
            20,
            21,
            21
        ],
        "文": [
            15,
            16,
            17,
            20,
            21,
            21
        ],
        "未": [
            15
        ],
        "显": [
            15,
            16
        ],
        "示": [
            15,
            16
        ],
        "派": [
            15,
            16
        ],
        "从": [
            16
        ],
        "开": [
            16
        ],
        "始": [
            16
        ],
        "算": [
            16
        ],
        "必": [
            17
        ],
        "须": [
            17
        ],
        "等": [
            17,
            18,
            20
        ],
        "可": [
            17,
            18,
            20,
            21
        ],
        "答": [
            17,
            18
        ],
        "辩": [
            17,
            18
        ],
        "参": [
            18,
            22
        ],
        "国": [
            18,
            22
        ],
        "际": [
            18,
            22
        ],
        "评": [
            18,
            19,
            22
        ],
        "里": [
            18,
            20,
            21
        ],
        "返": [
            18
        ],
        "段": [
            19
        ],
        "位": [
            19
        ],
        "催": [
            19
        ],
        "下": [
            19
        ],
        "家": [
            19
        ],
        "待": [
            20
        ],
        "重": [
            20
        ],
        "问": [
            20
        ],
        "好": [
            20
        ],
        "且": [
            21
        ],
        "点": [
            21
        ],
        "击": [
            21
        ],
        "退": [
            21
        ],
        "操": [
            22
        ],
        "作": [
            22
        ]
    }
}